export const security = {
  title: "Security",
  content:
    "<p>Security at IIT-R is one of those rare things you can absolutely rely upon. At the time of entry into the campus outsiders are required to submit their IDs so no unnecessary movement can disrupt the tranquility of the campus. Also, each campus building has its own security ‘check-point’. IIT-R has around 10 boys, 3 girls, 6 married and 1 co-ed hostel and these 20 Bhawans have their own security checkpoint. Facchas (First Year Students, check out IIT-R lingos) are not allowed to exit their Bhawan’s campus after 2300 hours due to security reasons for some initial months. So, all in all, security standards may sometimes bug you, but they are and will always be for your best.</p>",
}

export const ragging = {
  title: "Anti Ragging Policy",
  content:
    "<p>UGC Regulation on Curbing the Menace of Ragging in Higher Educational Institutions, 2009 completely forbids ragging of any kind in an institute of higher education. What this means for you as a potential student is that there is absolutely no reason to be afraid. If you’re looking for a safe stay, you will definitely get that and if you’re not looking for a safe stay, hmmm... As a matter of fact, IIT Roorkee can boast of a really good senior-junior culture.</p>",
}

export const banking = {
  title: "Banking",
  content:
    "<p>Once you step onto IITR’s lush green campus, you will find two major banks to take care of all your banking needs: Punjab National Bank and State Bank of India. The ATMs of both these banks can also be easily found in the campus. It is advisable to have an account in either of the two to streamline your finance-related work while on campus and both also provide student loans for anyone interested.</p> ",
}

export const stay = {
  title: "Where to stay",
  content: "",
}

export const wheretostay = [
  {
    name: "Khosla International House",
    content:
      "This unit, located near Mechanical and Civil Engineering Departments, has 9 AC suites, 40 AC Rooms, and 11 non-AC rooms. In each of them, two furnished beds are provided. Each of them has an attached toilet with a hot and cold water facility",
    image:
      "https://www.iitr.ac.in/campus_life/uploads/Image/iitr_khoslahouseinside.jpg",
    site:
      "https://www.iitr.ac.in/campus_life/pages/Guest_Houses+Khosla_International_House.html",
  },
  {
    name: " N.C. Nigam Visitors' Hostel",
    content:
      "It is one of the visitors' hostel present in the Campus. It offers world class facilities with a committee room and dining hall. There are 16 rooms and 12 suites in the visitors' hostel. All the rooms and suites are air conditioned and are well furnished.",
    image:
      "https://www.iitr.ac.in/campus_life/uploads/Image/iitr_mainguesthousefront.jpg",
    site:
      "https://www.iitr.ac.in/campus_life/pages/Guest_Houses+N_C__Nigam_Visitors__Hostel.html",
  },
]
