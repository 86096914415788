import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import { security } from "../../data/parents'-section"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"
function Security(props) {
  return (
    <div>
      <Layout>
        <SEO
          title={security.title}
          description={getWords(security.content, 60)}
        />
        <GeneralComp {...security} />
      </Layout>
    </div>
  )
}

export default Security
